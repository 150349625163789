import * as React from "react"
import { Link } from "gatsby"
import videoCover from "../images/cover.png"
import aist from "../images/aist.svg"
import sub from "../images/sub.png"
import backedLogo from "../images/backed_logo.png"
import { Helmet } from "react-helmet";

const SEO = ( ) => {
  const seo = {
    title: "Aiст Технологии | ИИ Решения для Анализа Корпоративных Данных",
    description: "Aiст Технологии предлагает передовые ИИ-инструменты для автоматизации анализа корпоративных данных, финансовых отчетов и документации.",
    url: `https://admirable-griffin-7aa9a7.netlify.app}`,
    image: aist, // Replace with a default image path
  };

  return (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <link rel="canonical" href={seo.url} />}
      
      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
    </Helmet>
  );
};

const IndexPage = () => {
  return (
  <div class="container">
    <SEO/>
    <div class="intro_bg"></div>
    <div class="page_title">
    <h1>Aiст Технологии</h1>

    </div>
    <img class="aist" src={aist} alt="Логотип Aiст Технологии" />
      <div class="news">
          <a class="news_post" href="https://calendly.com/aisttechco/30min" target="https://calendly.com/aisttechco/30min" rel="noreferrer">
            {/* <div></div> */}
            <p >Протестировать бета-версию</p>
          </a>
          <a class="news_post" href="" target="_blank" rel="noreferrer">
            {/* <div></div> */}
            <p>Прочитать документацию</p>
          </a>
      </div>
      <div class="intro_description">
        <p>Добро пожаловать в Aист Тех! Эволюция работы с корпоративными данными начинается здесь.</p>
      </div>
      <div class="backed-by-section">
        <p>При поддержке</p>
        <img src={backedLogo} alt="Логотип фонда" className="backed-logo" />
      </div>
      <div class="section helper">
        <Link className="helper_box" to="/soon">
          <h5>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5549_106488)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 4.5L12 0.5V3.5H1V5.5H12V8.5L16 4.5ZM4 8.5L0 12.5L4 16.5V13.5H15V11.5H4V8.5Z" fill="black"/>
            </g>
            <defs>
            <clipPath id="clip0_5549_106488">
            <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
            </defs>
            </svg>
            Попробуйте бета-версию сегодня 
          </h5>
          <p>Попробуйте нашу систему анализа корпоративных данных с языковой моделью ИИ бесплатно в Telegram.</p>
        </Link>
        <Link className="helper_box" to="/soon">
          <h5>
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5549_106503)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8335 2.5H12.8335C13.6619 2.5 14.3335 3.17157 14.3335 4C14.3335 4.82843 13.6619 5.5 12.8335 5.5H3.8335C3.00507 5.5 2.3335 4.82843 2.3335 4C2.3335 3.17157 3.00507 2.5 3.8335 2.5ZM0.333496 4C0.333496 2.067 1.9005 0.5 3.8335 0.5H12.8335C14.7665 0.5 16.3335 2.067 16.3335 4C16.3335 5.933 14.7665 7.5 12.8335 7.5H3.8335C1.9005 7.5 0.333496 5.933 0.333496 4ZM3.8335 11.5H12.8335C13.6619 11.5 14.3335 12.1716 14.3335 13C14.3335 13.8284 13.6619 14.5 12.8335 14.5H3.8335C3.00507 14.5 2.3335 13.8284 2.3335 13C2.3335 12.1716 3.00507 11.5 3.8335 11.5ZM0.333496 13C0.333496 11.067 1.9005 9.5 3.8335 9.5H12.8335C14.7665 9.5 16.3335 11.067 16.3335 13C16.3335 14.933 14.7665 16.5 12.8335 16.5H3.8335C1.9005 16.5 0.333496 14.933 0.333496 13Z" fill="black"/>
            </g>
            <defs>
            <clipPath id="clip0_5549_106503">
            <rect width="16" height="16" fill="white" transform="translate(0.333496 0.5)"/>
            </clipPath>
            </defs>
            </svg>
            Превратите данные в инсайты с ИИ
          </h5>
          <p>Подписка включает в себя мощные инструменты для анализа финансовых документов в формате диалога с ИИ. Быстро узнавайте нужное и копируйте данные, создавая XLSX.</p>
        </Link> 
        <Link className="helper_box" to="/soon">
          <h5>
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5549_106514)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.1665 0.5C3.50965 0.5 2.1665 1.84315 2.1665 3.5V13.5C2.1665 15.1569 3.50965 16.5 5.1665 16.5H14.1665H15.1665V14.5H14.6665V12.5H15.1665V10.5V2.5V1.5V0.5H5.1665ZM13.1665 2.5H5.1665C4.61422 2.5 4.1665 2.94772 4.1665 3.5V10.6707C4.47928 10.5602 4.81587 10.5 5.1665 10.5H13.1665V2.5ZM5.1665 12.5H12.6665V14.5H5.1665C4.61422 14.5 4.1665 14.0523 4.1665 13.5C4.1665 12.9477 4.61422 12.5 5.1665 12.5Z" fill="black"/>
            </g>
            <defs>
            <clipPath id="clip0_5549_106514">
            <rect width="16" height="16" fill="white" transform="translate(0.666504 0.5)"/>
            </clipPath>
            </defs>
            </svg>
            Откройте больше 
          </h5>
          <p>Получайте продвинутую аналитику по данным компании. Автоматизируйте с Aist api.</p>
        </Link> 
      </div>
      <div class="section-s newversion">
          <h2>УСКОРЕНИЕ ДЛЯ ЛЮБОГО  <br />БИЗНЕСА</h2>
          <p >Мы предлагаем сервис на базе искусственного интеллекта для автоматизации анализа корпоративных данных, финансовых отчетов и документации. Наша цель - помочь вам оптимизировать работу с информацией, сделав процесс поиска и обработки данных более простым и эффективным.</p>
          <a href="" class="mainnet_btn" target="_blank" rel="noreferrer">Начните сейчас</a>
      </div>
      <div class="section helper migration">
        <a class="helper_box" href="" target="_blank" rel="noreferrer">
          <h5>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5549_106525)">
            <path d="M10.0999 4.90308L0.0605469 14.9425L1.47476 16.3567L11.5148 6.31665L11.5148 14.2168H13.5148L13.5148 2.90308L2.20109 2.90308L2.20109 4.90308L10.0999 4.90308Z" fill="black"/>
            </g>
            <defs>
            <clipPath id="clip0_5549_106525">
            <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
            </defs>
            </svg>
            О ПРОЕКТЕ
          </h5>
          <p>Мы за будущее, где каждая компания может легко извлекать ценные инсайты из своих данных с помощью ИИ. Система из передовых языковых моделей и инструментов анализа трансформирует способ работы с информацией, делая его быстрым, точным и эффективным.</p>
        </a>
        <a class="helper_box teaser" href="" target="_blank" rel="noreferrer">
        </a>
      </div>
      <div class="section subscriptions">
      <div class="subscription-block">
        <div class="subscription-image">
        <img src={sub} alt="Подписка на услуги ИИ" />
        </div>
        <div class="subscription-details">
          <h2 class="subscription-header">Спрашивайте ваши документы, управляемые интеллектом</h2>
          <div class="subscription-type">
            <div class="subscription-type1">
              <h3>Ai и Инструменты </h3>
              <p>Языковая модель искусственного интеллекта (LLM), профи в финансах </p>
              <p>Загрузка и анализ корпоративных документов </p>
              <p>Чтение веб-страниц </p>
              <p>Интеграция с Google Docs</p>
              <p>Бета версия чат-бот в Telegram </p>
            </div>
            <div class="subscription-type2">
              <h3>Решения в Разработке</h3>
              <p>API доступ</p>
              <p>Авто-преобразование данных в формат Excel</p>
              <p>Доступ ИИ к новостям рынка</p>
              <p>Интеграция с корпоративными системами</p>
              <p>Персонализированные решения</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="funding-acknowledgment">
      <p>Проект создан при поддержке Федерального государственного бюджетного учреждения "Фонд содействия развитию малых форм предприятий в научно-технической сфере" в рамках программы "Студенческий стартап" федерального проекта "Платформа университетского технологического предпринимательства"</p>
    </div>
    </div>
    
  )
}

export default IndexPage
